import React, { memo, useEffect } from 'react';
import { useRoutes, useLocation } from 'react-router-dom';
// import ReactGA from 'react-ga';
import NotFound from '../screens/NotFound';
import appRoutes from './appRoutes';
// import practiceAccountRoutes from './practiceAccountRoutes';
import authRoutes from './authRoutes';

const Route = () => {
  const location = useLocation(); // Get current location

  useEffect(() => {
    // Track page view on every URL change
    // console.log('location.pathname: ', location.pathname, 'location.search: ', location.search);
    // ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  const element = useRoutes([
    ...authRoutes,
    ...appRoutes,
    // ...practiceAccountRoutes,
    { path: '*', element: <NotFound /> },
  ]);

  return element;
};

Route.propTypes = {};

export default memo(Route);
