/* eslint-disable  */
import React from 'react';
// import Typography from '@material-ui/core/Typography';
// import Grid from '@material-ui/core/Grid';
// import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  Grid,
  Menu,
  MenuItem,
  Button,
  Typography,
  Box,
  Tab,
  Tabs,
  InputBase,
  InputAdornment,
  TextField,
  Popover,
  FormControlLabel,
  Checkbox,
  Paper,
  InputLabel,
  Container,
  Hidden,
  Link,
  Avatar,
  Divider,
} from '@material-ui/core';

const JobLoader = () => {
  return (
    <Box sx={{ width: '100%' }}>
      <Paper className="JobPostBox">
        <Box className="JobPostLogo">
          <Skeleton variant="square">
            <Avatar className="JobPostLogo" />
          </Skeleton>
        </Box>
        <Box ml={2} className="JobPostContent">
          <Skeleton width="100%">
            <Typography>.</Typography>
          </Skeleton>
          <Skeleton width="100%">
            <Typography>.</Typography>
          </Skeleton>
        </Box>
        <Box className="JobPostIcon">
          {/* <FavoriteBorderIcon color="primary" /> */}
          <Skeleton width="100%">
            <Typography>.</Typography>
          </Skeleton>
        </Box>
      </Paper>

      <Paper className="JobPostBox">
        <Box className="JobPostLogo">
          <Skeleton variant="square">
            <Avatar className="JobPostLogo" />
          </Skeleton>
        </Box>
        <Box ml={2} className="JobPostContent">
          <Skeleton width="100%">
            <Typography>.</Typography>
          </Skeleton>
          <Skeleton width="100%">
            <Typography>.</Typography>
          </Skeleton>
        </Box>
        <Box className="JobPostIcon">
          {/* <FavoriteBorderIcon color="primary" /> */}
          <Skeleton width="100%">
            <Typography>.</Typography>
          </Skeleton>
        </Box>
      </Paper>

      <Paper className="JobPostBox">
        <Box className="JobPostLogo">
          <Skeleton variant="square">
            <Avatar className="JobPostLogo" />
          </Skeleton>
        </Box>
        <Box ml={2} className="JobPostContent">
          <Skeleton width="100%">
            <Typography>.</Typography>
          </Skeleton>
          <Skeleton width="100%">
            <Typography>.</Typography>
          </Skeleton>
        </Box>
        <Box className="JobPostIcon">
          {/* <FavoriteBorderIcon color="primary" /> */}
          <Skeleton width="100%">
            <Typography>.</Typography>
          </Skeleton>
        </Box>
      </Paper>
    </Box>
  );
};

export default JobLoader;
