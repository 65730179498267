/* eslint-disable */
import ajax from '../utils/ajaxHelper';
import { useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const useCommunityList = () => {
  const { requestId } = useParams();
  const navigate = useNavigate();

  const getCommunitiesByState = useCallback(async (val) => {
    try {
      const values = { stateId: val.stateId };
      return await ajax.post(`community/get-by-state`, values);
    } catch (error) {
      return error;
    }
  });

  const getAllCommunities = useCallback(async (val = null) => {
    try {
      return await ajax.post(`community/get-all`, val, {
        networkParams: { showLoader: false },
      });
    } catch (error) {
      return error;
    }
  });

  const getCommunityDetailById = useCallback(async (values) => {
    try {
      return await ajax.post(`community/get-community-details`, values);
    } catch (error) {
      return error;
    }
  });

  const getCommunitiesByUser = useCallback(async (val) => {
    try {
      const value = { userId: val.userId, typeText: val.typeText };

      return await ajax.post(`community/get-by-user`, value);
    } catch (error) {
      return error;
    }
  });

  const getStatusById = useCallback(async (val) => {
    try {
      const value = { userId: val.userId, communityId: val.communityId };
      return await ajax.post(`community/get-status`, value, {
        networkParams: { showLoader: false },
      });
    } catch (error) {
      return error;
    }
  });
  const getAmenityCategories = useCallback(async (val) => {
    try {
      return await ajax.post(`community/get-amenity-categories`, {
        networkParams: { showLoader: false },
      });
    } catch (error) {
      return error;
    }
  });

  const getAmenityByType = useCallback(async (val) => {
    try {
      const values = { communityId: val.communityId };
      return await ajax.post(`community/get-all-amenities`, values, {
        networkParams: { showLoader: false },
      });
    } catch (error) {
      return error;
    }
  });

  const getAmenityById = useCallback(async (val) => {
    try {
      const value = { id: val?.id };
      return await ajax.post(`community/get-amenity`, value, {
        networkParams: { showLoader: false },
      });
    } catch (error) {
      return error;
    }
  });

  const addCommunityAsFavourite = useCallback(async (val) => {
    try {
      const values = { userId: val.userId, communityId: val.communityId, typeText: val.typeText };

      return await ajax.post(`community/add-as-favourite`, values, {
        networkParams: { showLoader: false },
      });
    } catch (error) {
      return error;
    }
  });

  const updateCommunityAbout = useCallback(async (values) => {
    try {
      return await ajax.post(`community/update-community-about`, values);
    } catch (error) {
      return error;
    }
  });

  const deleteCoverPhoto = useCallback(async (values) => {
    try {
      return await ajax.post(`community/delete-cover-photo`, values);
    } catch (error) {
      return error;
    }
  });

  const updateCoverPhoto = useCallback(async (values) => {
    try {
      return await ajax.post(`community/update-cover-photo`, values, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (error) {
      return error;
    }
  });

  const approveVideo = useCallback(async (val) => {
    try {
      const values = {
        requestId: val.requestId,
        action: val.action,
        communityRepEmail: val.communityRepEmail,
        reason: val.reason,
        userName: val.userName,
      };
      return await ajax.post(`community/approve-video`, values, {
        networkParams: { showLoader: false },
      });
    } catch (error) {
      return error;
    }
  });

  const approveEditVideoRequest = useCallback(async (val) => {
    try {
      const values = {
        requestId: val.requestId,
        action: val.action,
        communityRepEmail: val.communityRepEmail,
        reason: val.reason,
        userName: val.userName,
      };
      return await ajax.post(`community/approve-edited-video`, values, {
        networkParams: { showLoader: false },
      });
    } catch (error) {
      return error;
    }
  });

  const uploadVideoRequest = useCallback(async (values) => {
    try {
      return await ajax.post(`community/upload-video`, values, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (error) {
      return error;
    }
  });

  const editVideoRequest = useCallback(async (values) => {
    try {
      return await ajax.post(`community/edit-video`, values, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (error) {
      return error;
    }
  });

  const sendCustomVideoRequest = useCallback(async (val) => {
    try {
      const values = {
        communityRepEmail: val.communityRepEmail,
        repId: val.repId,
        communityId: val.communityId,
        userName: val.userName,
      };
      return await ajax.post(`community/send-video-consultation-request`, values, {});
    } catch (error) {
      return error;
    }
  });

  const getCustomVideoRequestData = useCallback(async (val) => {
    try {
      const values = {
        communityId: val.communityId,
        // repId: val.repId,
      };
      return await ajax.post(`community/get-video-consultation-data`, values);
    } catch (error) {
      return error;
    }
  });

  const getVideoRequestData = useCallback(async (val) => {
    try {
      const values = {
        communityId: val.communityId,
        // repId: val.repId,
      };
      return await ajax.post(`community/get-video-request-data`, values);
    } catch (error) {
      return error;
    }
  });

  const getVideoRequestDataForApproval = useCallback(async (val) => {
    try {
      const values = {
        requestId: val.requestId,
        // repId: val.repId,
      };
      return await ajax.post(`community/get-video-request-by-id`, values);
    } catch (error) {
      return error;
    }
  });

  const readVideoNotification = useCallback(async (val) => {
    try {
      const values = {
        videoId: val.videoId,
      };
      return await ajax.post(`community/request-read`, values);
    } catch (error) {
      return error;
    }
  });

  const markConsultVideoRequestAsRead = useCallback(async (val) => {
    try {
      const values = {
        requestId: val.requestId,
      };
      return await ajax.post(`community/request-consult-read`, values);
    } catch (error) {
      return error;
    }
  });

  const deleteVideoRequest = useCallback(async (val) => {
    try {
      const values = {
        videoId: val.videoId,
        communityRepEmail: val.communityRepEmail,
      };
      return await ajax.post(`community/delete-video-request`, values);
    } catch (error) {
      return error;
    }
  });

  const createNewCommunity = useCallback(async (val) => {
    try {
      console.log('createNewCommunity Inside');
      const values = {
        communityNameText: val?.communityNameText,
        state: val?.state,
        city: val?.city,
        lat: val?.lat,
        long: val?.long,
      };
      console.log('createNewCommunity Values', values);
      const data = await ajax.post(`community/create`, values);
      console.log('createNewCommunity data', data);
      return data;
    } catch (error) {
      console.log('createNewCommunity error', error);
      return error;
    }
  });

  const addJboardImportIds = useCallback(async (val) => {
    try {
      const values = {
        jBoardId1: val.jBoardId1,
        jBoardId2: val.jBoardId2,
        jBoardId3: val.jBoardId3,
        jBoardId4: val.jBoardId4,
        communityId: val.communityId,
      };
      return await ajax.post(`community/add-jobs-by-importid`, values);
    } catch (error) {
      return error;
    }
  });

  const editJboardImportIds = useCallback(async (val) => {
    try {
      const values = {
        jBoardId1: val.jBoardId1,
        jBoardId2: val.jBoardId2,
        jBoardId3: val.jBoardId3,
        jBoardId4: val.jBoardId4,
        communityId: val.communityId,
      };
      return await ajax.post(`community/edit-jobs-by-importid`, values);
    } catch (error) {
      return error;
    }
  });

  const getJboardImportIds = useCallback(async (val) => {
    try {
      const values = {
        communityId: val.communityId,
      };
      return await ajax.post(`community/get-jobs-by-importid`, values);
    } catch (error) {
      return error;
    }
  });

  const addCommunityCatResidents = useCallback(async (val) => {
    try {
      const values = {
        repId: val.repId,
        communityId: val.communityId,
        residentsDesc: val.residentsDesc,
        isAdminEntry: val.isAdminEntry,
      };
      return await ajax.post(`community/add-community-residents`, values);
    } catch (error) {
      return error;
    }
  });

  const addCommunityCostOfLiving = useCallback(async (val) => {
    try {
      const values = {
        repId: val.repId,
        communityId: val.communityId,
        costOfLivingType: val.costOfLivingType,
        costOfLivingDesc: val.costOfLivingDesc,
        isAdminEntry: val.isAdminEntry,
      };
      console.log(values, 2000);
      return await ajax.post(`community/add-community-cost-of-living`, values);
    } catch (error) {
      return error;
    }
  });

  const addCommunityCatClimate = useCallback(async (val) => {
    try {
      const values = {
        repId: val.repId,
        communityId: val.communityId,
        climateDesc: val.climateDesc,
        climateType: val.climateType,
        isAdminEntry: val.isAdminEntry,
      };
      console.log(values, 2000);
      return await ajax.post(`community/add-community-climate`, values);
    } catch (error) {
      return error;
    }
  });

  const addCommunityHealthInsights = useCallback(async (val) => {
    try {
      const values = {
        communityId: val.communityId,
        repId: val.repId,
        healthCareDesc: val.healthCareDesc,
        isAdminEntry: val.isAdminEntry,
      };
      console.log(values, 2000);
      return await ajax.post(`community/add-community-health-insights`, values);
    } catch (error) {
      return error;
    }
  });

  const addCommunityHealthFacility = useCallback(async (val) => {
    try {
      const values = {
        repId: val.repId,
        communityId: val.communityId,
        facilityType: val.facilityType,
        facilityName: val.facilityName,
        facilityAddress: val.facilityAddress,
        facilityPhone: val.facilityPhone,
        facilityWebUrl: val.facilityWebUrl,
        isAdminEntry: val?.isAdminEntry,
      };
      console.log(values, 2000);
      return await ajax.post(`community/add-community-health-facility`, values);
    } catch (error) {
      return error;
    }
  });

  const getCommunityHealthFacility = useCallback(async (val) => {
    try {
      const values = {
        communityId: val.communityId,
        // repId: val.repId,
        facilityId: val.facilityId,
      };
      return await ajax.post(`community/get-all-community-health-facilities`, values);
    } catch (error) {
      return error;
    }
  });

  const getByFacilityId = useCallback(async (val) => {
    try {
      const values = {
        facilityId: val.facilityId,
      };
      console.log(values, 2000);
      return await ajax.post(`community/get-by-facilityId`, values);
    } catch (error) {
      return error;
    }
  });

  const getEducationSchools = useCallback(async (val) => {
    try {
      const values = {
        // lat: val.lat,
        // long: val.long,
        communityId: val.communityId,
      };
      return await ajax.post(`community/get-schools-education`, values);
    } catch (error) {
      return error;
    }
  });

  const updateCommunityHeathFacility = useCallback(async (facilityId, values) => {
    console.log(values, 200);
    try {
      return await ajax.post(`community/update-community-health-facility`, JSON.stringify(values), {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } catch (error) {
      return error;
    }
  });

  const deleteCommunityHeathFacility = useCallback(async (facilityId) => {
    try {
      return await ajax.post(
        `community/delete-community-health-facility`,
        JSON.stringify({ facilityId }),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
    } catch (error) {
      return error;
    }
  });

  const shareCommunityThroughEmail = useCallback(async (val) => {
    try {
      return await ajax.post(`share-community-email`, val);
    } catch (error) {
      return error;
    }
  });

  const addCommunityCatGettingAround = useCallback(async (val) => {
    try {
      const values = {
        repId: val.repId,
        communityId: val.communityId,
        gettingAroundDesc: val.gettingAroundDesc,
        publicTransType: val.publicTransType,
        dedicatedWalkingPaths: val.dedicatedWalkingPaths,
        dedicatedBikingPaths: val.dedicatedBikingPaths,
        localAirport: val.localAirport,
        regionalAirport: val.regionalAirport,
        isAdminEntry: val.isAdminEntry,
      };
      console.log(values, 2000);
      return await ajax.post(`community/add-community-getting-around`, values);
    } catch (error) {
      return error;
    }
  });

  const addCommunityCatEducation = useCallback(async (val) => {
    try {
      const values = {
        repId: val.repId,
        communityId: val.communityId,
        educationPreDesc: val.educationPreDesc,
        educationPostDesc: val.educationPostDesc,
        publicType: val.publicType,
        privateType: val.privateType,
        faithBasedPrivate: val.faithBasedPrivate,
        specialNeedsPrivate: val.specialNeedsPrivate,
        publicTechnicalJuniorCollege: val.publicTechnicalJuniorCollege,
        privateTechnicalJuniorCollege: val.privateTechnicalJuniorCollege,
        publicFourYearCollegeUniversity: val.publicFourYearCollegeUniversity,
        privatecFourYearCollegeUniversity: val.privatecFourYearCollegeUniversity,
        homeschoolResourceGroups: val.homeschoolResourceGroups,
        charter: val.charter,
        isAdminEntry: val.isAdminEntry,
      };
      console.log(values, 2000);
      return await ajax.post(`community/add-community-education`, values);
    } catch (error) {
      return error;
    }
  });

  const addAmenityMaster = useCallback(async (values) => {
    try {
      console.log(values, 7777);
      return await ajax.post(
        `community/add-amenity`,
        values,
        {
          networkParams: { showLoader: true },
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
    } catch (error) {
      return error;
    }
  });

  const deleteAmenity = useCallback(async (val) => {
    try {
      const values = {
        id: val.id,
      };
      return await ajax.post(`community/delete-amenity`, values);
    } catch (error) {
      return error;
    }
  });

  const addAmenitiesDescForCommunity = useCallback(async (val) => {
    try {
      const values = {
        repId: val.repId,
        communityId: val.communityId,
        amenitiesDesc: val.amenitiesDesc,
        isAdminEntry: val.isAdminEntry,
      };
      console.log(values, 2000);
      return await ajax.post(`community/add-amenity-description`, values);
    } catch (error) {
      return error;
    }
  });

  const updateCommunityCatJobs = useCallback(async (values) => {
    try {
      console.log(values, 2000);
      return await ajax.post(`community/update-community-cat-jobs`, values);
    } catch (error) {
      return error;
    }
  });

  const updateAcsFieldById = useCallback(async (values) => {
    try {
      const values = {
        fieldName: val.fieldName,
        communityId: val.communityId,
        fieldValue: val.fieldValue,
      };
      console.log(values, 2000);
      return await ajax.post(`community/update-acs-field`, values);
    } catch (error) {
      return error;
    }
  });

  return {
    getCommunitiesByState,
    getCommunitiesByUser,
    addCommunityAsFavourite,
    getAllCommunities,
    getCommunityDetailById,
    getStatusById,
    shareCommunityThroughEmail,
    // addEvent,
    // updateSelectedEvent,
    // getCalendarEvents,
    // deleteEvent,
    // getSingleEvent,
    updateCommunityAbout,
    addCommunityCatResidents,
    addCommunityCatClimate,
    addCommunityCatGettingAround,
    addCommunityCatEducation,
    getEducationSchools,
    addAmenityMaster,
    addAmenitiesDescForCommunity,
    updateCommunityCatJobs,
    addCommunityHealthFacility,
    getCommunityHealthFacility,
    updateCommunityHeathFacility,
    deleteCommunityHeathFacility,
    addCommunityHealthInsights,
    updateAcsFieldById,
    getAmenityCategories,
    getAmenityById,
    getAmenityByType,
    deleteAmenity,
    updateCoverPhoto,
    deleteCoverPhoto,
    addCommunityCostOfLiving,
    uploadVideoRequest,
    getVideoRequestData,
    deleteVideoRequest,
    approveVideo,
    editVideoRequest,
    approveEditVideoRequest,
    readVideoNotification,
    sendCustomVideoRequest,
    getCustomVideoRequestData,
    markConsultVideoRequestAsRead,
    getVideoRequestDataForApproval,
    createNewCommunity,
    addJboardImportIds,
    editJboardImportIds,
    getJboardImportIds,
    getByFacilityId,
  };
};

export default useCommunityList;
