/* eslint-disable global-require */
/* eslint-disable  */

import React from 'react';
import {
  Grid,
  Typography,
  Button,
  CircularProgress,
  Box,
  Divider,
  Checkbox,
  FormControlLabel,
  TextareaAutosize,
  IconButton,
  Container,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import { KEY } from '../../../constants/globalConstants';

const DialogTitle = (props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className="Root" {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className="CloseButton" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const DeclineInvitationSuccess = () => {
  const navigate = useNavigate();

  const [state, setState] = React.useState({
    checkedA: true,
  });
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const recaptchaRef = React.useRef();

  return (
    <Formik>
      {({ errors, isSubmitting, isValid, dirty, touched }) => {
        return (
          <Box className="PageSpacing DeclineInvitationSuccessPage">
            <Container>
              <Form autoComplete="off">
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="center"
                  className="ContainerCenter"
                >
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container alignItems="center">
                      <Grid item xs={12} justifyContent="center">
                        <Typography variant="h3" className="loginheading TextColor">
                          Your invitation has been declined
                        </Typography>
                        <Box mb={3} mt={1} textAlign="center">
                          <Typography variant="body2">Press close to continue</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} alignContent="center">
                        <Box display="flex" justifyContent="center">
                          <Button
                            // disabled={isSubmitting || !dirty || !isValid}
                            type="button"
                            className=""
                            variant="contained"
                            color="primary"
                            onClick={() => navigate('/app/home-page')}
                            // onClick={handleClose}
                          >
                            Close
                          </Button>
                          {isSubmitting && (
                            <CircularProgress size={24} className="ButtonProgress" />
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            </Container>
          </Box>
        );
      }}
    </Formik>
  );
};

export default DeclineInvitationSuccess;
