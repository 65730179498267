/* eslint-disable  */
import React from 'react';
import {
  Grid,
  Typography,
  TextField,
  Button,
  CircularProgress,
  InputLabel,
  Box,
  Container,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Formik, FastField, Form } from 'formik';
import { object, string } from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { KEY } from '../../../constants/globalConstants';
import useAuth from '../../../hooks/useAuth';
import '../Auth.style.css';
import { Navigate, useNavigate } from 'react-router';
import { showErrIfTouched } from '../../../utils';
import PropTypes from 'prop-types';

const ForgotPasswordSchema = object().shape({
  emailId: string().required('Required'),
});

const ResetPassword = ({ setValue }) => {
  const { resetPasswordMail } = useAuth();
  const navigate = useNavigate();

  const recaptchaRef = React.useRef();

  const backToLogin = () => {
    navigate('../home-page?back=0');
  };

  return (
    <Formik
      initialValues={{ emailId: '' }}
      validationSchema={ForgotPasswordSchema}
      onSubmit={async (values, actions) => {
        const token = await recaptchaRef.current.executeAsync();
        localStorage.setItem('emailId', values.emailId);
        if (token) {
          values.recapToken = token;
          resetPasswordMail(values, actions, recaptchaRef.current);
        }
      }}
    >
      {({ errors, isSubmitting, isValid, dirty, touched }) => {
        return (
          <Box className="PageSpacing ForgotPasswordPage">
            <Container>
              <Form autoComplete="off">
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  className="MainContainer"
                >
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container alignItems="center">
                      <Box className="TabBoxSecond">
                        <Grid item xs={12}>
                          <Box mb={3}></Box>
                          <Box textAlign="center" mb={2} />

                          <Typography variant="h2" className="loginheading FlexCenter">
                            <img
                              className="HeadingIcon"
                              src={
                                require('../../../assets/images/Forgot-Password-Icon.png').default
                              }
                              alt="Forgot Your Password Icon"
                            />
                            Forgot Your Password?
                          </Typography>
                          <Box textAlign="center" mt={3} mb={3}>
                            <Typography variant="body2">
                              Please enter your email below, we will send you a link to reset your
                              password.
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <InputLabel className="InputLabelFld" htmlFor="email">
                            Email
                            <Box
                              mb={1}
                              component="span"
                              display="inline-block"
                              className="Asterisk"
                            >
                              *
                            </Box>
                          </InputLabel>
                          <FastField
                            as={TextField}
                            name="emailId"
                            id="outlined-basic"
                            variant="outlined"
                            type="email"
                            error={!!showErrIfTouched('emailId', errors, touched)}
                            helperText={showErrIfTouched('emailId', errors, touched)}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <div className="ButtonWrapper">
                            <Button
                              disabled={isSubmitting || !dirty || !isValid}
                              type="submit"
                              className="loginButton"
                              variant="contained"
                              color="primary"
                            >
                              Send Link
                            </Button>
                            {isSubmitting && (
                              <CircularProgress size={24} className="ButtonProgress" />
                            )}
                          </div>
                          <Box mt={2} textAlign="center" className="TextCenterXS">
                            <Typography variant="body2">
                              Know your password?
                              <Button
                                style={{ textDecoration: 'inherit' }}
                                className="SignUpButton LinkTypeButton"
                                onClick={() => backToLogin('../')}
                              >
                                Back to Log In
                              </Button>
                            </Typography>
                          </Box>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid container>
                      <Grid>
                        <ReCAPTCHA ref={recaptchaRef} sitekey={KEY.SITE} size="invisible" />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            </Container>
          </Box>
        );
      }}
    </Formik>
  );
};

ResetPassword.propTypes = {
  setValue: PropTypes.number.isRequired,
};

export default ResetPassword;
